import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice, Section, Heading } from 'components';

const IS_BROWSER = typeof window !== 'undefined';

export const Article = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { prismicArticle, authors } = data;
  const { data: pageData, uid } = prismicArticle;
  const { date_published: date } = pageData;
  const url = `/insights/${uid}`;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
  } = pageData;
  const seo = {
    title: metaTitle?.text,
    desc: metaDesc?.text,
    banner: ogImage?.localFile?.childImageSharp?.fixed?.src,
    schema: schema?.text,
  };
  const authorProfile = authors?.nodes?.find((author) => author?.primary?.email === pageData.author);

  return (
    <Layout location={location} seo={seo}>
      {sliceData?.map((slice) => (
        <Slice key={slice.id} data={slice} context={{ authorProfile, url, date, category: pageData?.category }} />
      ))}
    </Layout>
  );
};

export default Article;

export const articleQuery = graphql`
  query ArticleBySlug($uid: String!) {
    prismicArticle(uid: { eq: $uid }) {
      id
      uid
      prismicId
      last_publication_date(formatString: "D MMM YYYY")
      data {
        title {
          text
        }
        category
        author
        date_published(formatString: "D MMM YYYY")
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        body {
          ...PrismicArticleBodyHeadingArticleFragment
          ...PrismicArticleBodyAuthorFragment
          ...PrismicArticleBodyTextFragment
          ...PrismicArticleBodyVideoFragment
          ...PrismicArticleBodyQuoteFragment
          ...PrismicArticleBodyImageFragment
          ...PrismicArticleBodyQuickLinksFragment
          ...PrismicArticleBodyFeaturedProjectsFragment
          ...PrismicArticleBodyFeaturedArticlesFragment
          ...PrismicArticleBodyFeaturedServicesFragment
          ...PrismicArticleBodyFeaturedContentFragment
          ...PrismicArticleBodyEbookFragment
          ...PrismicArticleBodyStatisticsFragment
          ...PrismicArticleTwoColsImageText
        }
      }
    }
    authors: allPrismicSettingsBodyTeamMember {
      nodes {
        primary {
          email
          role {
            text
          }
          photo {
            url
            alt
          }
          name {
            text
          }
          description {
            html
          }
        }
      }
    }
  }
`;
